var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      ref: "layout",
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        drawerWidth: 800,
        permission: _vm.isTongChou
          ? "production:chapter:button:edit"
          : "production:chapter:button:edit:isTongchou",
        btnLoading: _vm.btnLoading,
        tasks: _vm.tasks,
        isChapter: _vm.isNewForm,
      },
      on: {
        show: _vm.show,
        closed: _vm.closedModel,
        save: _vm.saveForm,
        edit: _vm.edit,
        showScreen: _vm.showScreen,
      },
    },
    [
      _c(
        "div",
        {},
        [
          _c("info-row", { attrs: { label: "标题" } }, [
            _vm._v(_vm._s(_vm.curRow.chapterName)),
          ]),
          _c("info-row", { attrs: { label: "序号" } }, [
            _vm._v(
              _vm._s(_vm.curRow.sequenceNo >= 0 ? _vm.curRow.sequenceNo : "")
            ),
          ]),
          _c("info-row", { attrs: { label: "结算数量" } }, [
            _vm._v(
              _vm._s(
                _vm.getCalcUnits(
                  _vm.curRow.calcUnits
                    ? _vm.curRow.calcUnits.filter((c) => {
                        return (
                          _vm.calcUnits.findIndex((i) => i.type == c.type) >
                            -1 &&
                          c.value &&
                          c.value > 0
                        )
                      })
                    : []
                )
              )
            ),
          ]),
          _c("info-row", { attrs: { label: "章节状态" } }, [
            _vm._v(_vm._s(_vm.curRow.chapterState)),
          ]),
          _c("info-row", { attrs: { label: "当前阶段" } }, [
            _vm._v(_vm._s(_vm.curRow.stageName)),
          ]),
          _c("info-row", { attrs: { label: "当前人员" } }, [
            _c("img", {
              staticStyle: {
                width: "30px",
                height: "30px",
                "border-radius": "50%",
                "margin-right": "10px",
              },
              attrs: {
                src: _vm.curRow.user ? _vm.curRow.user.avatar : "",
                alt: "",
              },
            }),
            _vm._v(_vm._s(_vm.curRow.user ? _vm.curRow.user.userName : "")),
          ]),
          _c("info-row", { attrs: { label: "当前截止日期" } }, [
            _vm._v(_vm._s(_vm.curRow.currDeadline)),
          ]),
          _c("info-row", { attrs: { label: "交稿日期" } }, [
            _vm._v(_vm._s(_vm.curRow.deliveryDate)),
          ]),
        ],
        1
      ),
      _vm.$refs.layout && !_vm.$refs.layout.isFullScreen
        ? _c("div", { attrs: { slot: "form" }, slot: "form" }, [
            _vm.isNewForm
              ? _c(
                  "div",
                  [
                    _c(
                      "a-form-model",
                      _vm._b(
                        {
                          ref: "dataForm",
                          attrs: {
                            layout: "horizontal",
                            model: _vm.formData,
                            rules: _vm.rules,
                          },
                        },
                        "a-form-model",
                        _vm.layout,
                        false
                      ),
                      [
                        _vm.curStep == 0
                          ? _c(
                              "a-form-model-item",
                              { attrs: { label: "章节数量" } },
                              [
                                _c("a-input-number", {
                                  staticClass: "w-40",
                                  attrs: {
                                    placeholder: "请输入1-20的整数数字",
                                    min: 1,
                                  },
                                  on: { change: _vm.nextStep },
                                  model: {
                                    value: _vm.chatperCount,
                                    callback: function ($$v) {
                                      _vm.chatperCount = $$v
                                    },
                                    expression: "chatperCount",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-left": "5px",
                                      "font-size": "12px",
                                      color: "#bfbfbf",
                                    },
                                  },
                                  [_vm._v("请输入1-20的整数数字")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.curStep == 0
                          ? _c(
                              "a-form-model-item",
                              { attrs: { label: "自动排期" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      height: "40px",
                                      "align-items": "center",
                                      "justify-content": "space-between",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio-group",
                                      {
                                        on: {
                                          change: _vm.radioAutoCalculateChange,
                                        },
                                        model: {
                                          value: _vm.autoCalculate,
                                          callback: function ($$v) {
                                            _vm.autoCalculate = $$v
                                          },
                                          expression: "autoCalculate",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "1" } },
                                          [_vm._v("是")]
                                        ),
                                        _c(
                                          "a-radio",
                                          { attrs: { value: "0" } },
                                          [_vm._v("否")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.autoCalculate == "1"
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              flex: "1",
                                              "justify-content": "space-around",
                                            },
                                          },
                                          [
                                            _vm.workMsgs.length
                                              ? _c(
                                                  "a-tooltip",
                                                  {
                                                    attrs: {
                                                      overlayClassName:
                                                        "user-msg-tooltip",
                                                      color: "white",
                                                      placement: "bottom",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "title",
                                                          fn: function () {
                                                            return [
                                                              _c("div", [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "padding-left":
                                                                          "15px",
                                                                        "font-weight":
                                                                          "700",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "未填写人员"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "300px",
                                                                        "overflow-y":
                                                                          "auto",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.workMsgs,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "p",
                                                                        {
                                                                          key: item.userId,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              key: item.userId,
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "35px",
                                                                                  height:
                                                                                    "35px",
                                                                                  "border-radius":
                                                                                    "50%",
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  src: item.avatar,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.userName
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2130295757
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "a-checkbox",
                                                      {
                                                        attrs: {
                                                          checked:
                                                            _vm.checkedList.some(
                                                              (item) =>
                                                                item ==
                                                                "可工作日"
                                                            ),
                                                        },
                                                        on: {
                                                          mouseenter: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleMouseEnter(
                                                              "可工作日"
                                                            )
                                                          },
                                                          change: (e) =>
                                                            _vm.onCheckChange(
                                                              e,
                                                              "可工作日"
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  可工作日\n                "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "a-checkbox",
                                                  {
                                                    attrs: {
                                                      checked:
                                                        _vm.checkedList.some(
                                                          (item) =>
                                                            item == "可工作日"
                                                        ),
                                                    },
                                                    on: {
                                                      mouseenter: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleMouseEnter(
                                                          "可工作日"
                                                        )
                                                      },
                                                      change: (e) =>
                                                        _vm.onCheckChange(
                                                          e,
                                                          "可工作日"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                可工作日\n              "
                                                    ),
                                                  ]
                                                ),
                                            _vm.stageMsgs.length
                                              ? _c(
                                                  "a-tooltip",
                                                  {
                                                    attrs: {
                                                      overlayClassName:
                                                        "user-msg-tooltip",
                                                      color: "white",
                                                      placement: "bottom",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "title",
                                                          fn: function () {
                                                            return [
                                                              _c("div", [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "padding-left":
                                                                          "15px",
                                                                        "font-weight":
                                                                          "700",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "未填写人员"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "300px",
                                                                        "overflow-y":
                                                                          "auto",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.stageMsgs,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "p",
                                                                        {
                                                                          key: item.userId,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              key: item.userId,
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "35px",
                                                                                  height:
                                                                                    "35px",
                                                                                  "border-radius":
                                                                                    "50%",
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  src: item.avatar,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.userName
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1755561864
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "a-checkbox",
                                                      {
                                                        attrs: {
                                                          checked:
                                                            _vm.checkedList.some(
                                                              (item) =>
                                                                item ==
                                                                "阶段耗时"
                                                            ),
                                                        },
                                                        on: {
                                                          mouseenter: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleMouseEnter(
                                                              "阶段耗时"
                                                            )
                                                          },
                                                          change: (e) =>
                                                            _vm.onCheckChange(
                                                              e,
                                                              "阶段耗时"
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  阶段耗时\n                "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "a-checkbox",
                                                  {
                                                    attrs: {
                                                      checked:
                                                        _vm.checkedList.some(
                                                          (item) =>
                                                            item == "阶段耗时"
                                                        ),
                                                    },
                                                    on: {
                                                      mouseenter: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleMouseEnter(
                                                          "阶段耗时"
                                                        )
                                                      },
                                                      change: (e) =>
                                                        _vm.onCheckChange(
                                                          e,
                                                          "阶段耗时"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                阶段耗时\n              "
                                                    ),
                                                  ]
                                                ),
                                            _vm.numMsgs.length
                                              ? _c(
                                                  "a-tooltip",
                                                  {
                                                    attrs: {
                                                      overlayClassName:
                                                        "user-msg-tooltip",
                                                      color: "white",
                                                      placement: "bottom",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "title",
                                                          fn: function () {
                                                            return [
                                                              _c("div", [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "padding-left":
                                                                          "15px",
                                                                        "font-weight":
                                                                          "700",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "未填写人员"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "300px",
                                                                        "overflow-y":
                                                                          "auto",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.numMsgs,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "p",
                                                                        {
                                                                          key: item.userId,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              key: item.userId,
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "35px",
                                                                                  height:
                                                                                    "35px",
                                                                                  "border-radius":
                                                                                    "50%",
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  src: item.avatar,
                                                                                },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.userName
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2146287770
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      "a-checkbox",
                                                      {
                                                        attrs: {
                                                          checked:
                                                            _vm.checkedList.some(
                                                              (item) =>
                                                                item ==
                                                                "可安排数量"
                                                            ),
                                                        },
                                                        on: {
                                                          mouseenter: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleMouseEnter(
                                                              "可安排数量"
                                                            )
                                                          },
                                                          change: (e) =>
                                                            _vm.onCheckChange(
                                                              e,
                                                              "可安排数量"
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  可安排数量\n                "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "a-checkbox",
                                                  {
                                                    attrs: {
                                                      checked:
                                                        _vm.checkedList.some(
                                                          (item) =>
                                                            item == "可安排数量"
                                                        ),
                                                    },
                                                    on: {
                                                      mouseenter: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleMouseEnter(
                                                          "可安排数量"
                                                        )
                                                      },
                                                      change: (e) =>
                                                        _vm.onCheckChange(
                                                          e,
                                                          "可安排数量"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                可安排数量\n              "
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "list-form" },
                          [
                            _c(
                              "a-row",
                              { attrs: { gutter: 16 } },
                              [
                                _c("a-col", { attrs: { span: 3 } }, [
                                  _c("div", { staticClass: "py-3" }, [
                                    _vm._v("章节序号"),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { span: 3 } }, [
                                  _c("div", { staticClass: "py-3" }, [
                                    _vm._v("\n                标题"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "5px",
                                          color: "#bfbfbf",
                                          "font-size": "12px",
                                        },
                                      },
                                      [_vm._v("非必填")]
                                    ),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { span: 5 } }, [
                                  _c("div", { staticClass: "py-3" }, [
                                    _vm._v("结算数量"),
                                  ]),
                                ]),
                                _c("a-col", { attrs: { span: 7 } }, [
                                  _c(
                                    "div",
                                    { staticClass: "py-3" },
                                    [
                                      _c(
                                        "a-radio-group",
                                        {
                                          on: { change: _vm.radioChange },
                                          model: {
                                            value: _vm.dateType,
                                            callback: function ($$v) {
                                              _vm.dateType = $$v
                                            },
                                            expression: "dateType",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: "1" } },
                                            [_vm._v("给稿日期")]
                                          ),
                                          _c(
                                            "a-radio",
                                            { attrs: { value: "2" } },
                                            [_vm._v("交稿日期")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("a-col", { attrs: { span: 4 } }, [
                                  _c("div", { staticClass: "py-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dateType == "1"
                                          ? "预计交稿日期"
                                          : "预计给稿日期"
                                      )
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.formData.chapters,
                              function (item, index) {
                                return _c(
                                  "a-row",
                                  {
                                    key: index,
                                    staticClass: "my-4",
                                    style:
                                      index == _vm.formData.chapters.length - 1
                                        ? "padding-bottom:36px;"
                                        : "",
                                    attrs: { gutter: 16 },
                                  },
                                  [
                                    _c(
                                      "a-col",
                                      { attrs: { span: 3 } },
                                      [
                                        _c("a-input-number", {
                                          on: {
                                            change: (e) => {
                                              _vm.sequenceNoChange(
                                                e,
                                                index,
                                                item
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.sequenceNo,
                                            callback: function ($$v) {
                                              _vm.$set(item, "sequenceNo", $$v)
                                            },
                                            expression: "item.sequenceNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 3 } },
                                      [
                                        _c("a-input", {
                                          model: {
                                            value: item.chapterName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "chapterName", $$v)
                                            },
                                            expression: "item.chapterName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      {
                                        staticClass: "pageCount-input",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "align-items": "center",
                                        },
                                        attrs: { span: 5 },
                                      },
                                      _vm._l(item.calcUnits, function (i, idx) {
                                        return _c(
                                          "span",
                                          {
                                            key: idx,
                                            staticStyle: {
                                              display: "flex",
                                              "justify-content":
                                                "space-between",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c("a-input-number", {
                                              attrs: { min: 0 },
                                              model: {
                                                value: i.value,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "value", $$v)
                                                },
                                                expression: "i.value",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "padding-left": "5px",
                                                  "padding-right": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.getUnit(i.type))
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 7 } },
                                      [
                                        _vm.dateType == "1"
                                          ? _c("a-date-picker", {
                                              attrs: {
                                                format: "YYYY-MM-DD HH:mm",
                                                valueFormat: "YYYY-MM-DD HH:mm",
                                                "show-time": {
                                                  format: "HH:mm",
                                                  defaultValue: _vm.moment(
                                                    "23:59",
                                                    "HH:mm"
                                                  ),
                                                },
                                                placeholder: "请选择具体日期",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.timeChange(
                                                    "draftTime",
                                                    index,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.draftTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "draftTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.draftTime",
                                              },
                                            })
                                          : _c("a-date-picker", {
                                              attrs: {
                                                valueFormat: "YYYY-MM-DD HH:mm",
                                                "show-time": {
                                                  format: "HH:mm",
                                                  defaultValue: _vm.moment(
                                                    "23:59",
                                                    "HH:mm"
                                                  ),
                                                },
                                                format: "YYYY-MM-DD HH:mm",
                                                placeholder: "请选择具体日期",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.timeChange(
                                                    "deliveryTime",
                                                    index,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.deliveryTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "deliveryTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.deliveryTime",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "a-spin",
                                          { attrs: { spinning: item.loading } },
                                          [
                                            _vm.dateType == "1"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "line-height": "32px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          item.deliveryTime
                                                            ? item.deliveryTime
                                                            : "—"
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "line-height": "32px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          item.draftTime
                                                            ? item.draftTime
                                                            : "—"
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "a-form-model",
                      _vm._b(
                        {
                          ref: "dataForm",
                          attrs: {
                            layout: "horizontal",
                            model: _vm.formData,
                            rules: _vm.rules,
                          },
                        },
                        "a-form-model",
                        _vm.layout,
                        false
                      ),
                      [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "章节序号" } },
                          [
                            _c("a-input-number", {
                              attrs: { placeholder: "请输入序号" },
                              model: {
                                value: _vm.formData.sequenceNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "sequenceNo", $$v)
                                },
                                expression: "formData.sequenceNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "标题", prop: "chapterName" } },
                          [
                            _c("a-input", {
                              model: {
                                value: _vm.formData.chapterName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "chapterName", $$v)
                                },
                                expression: "formData.chapterName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "结算数量", prop: "calcUnits" } },
                          [
                            _vm.formData.calcUnits
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.formData.calcUnits.filter((c) => {
                                        return (
                                          _vm.calcUnits.findIndex(
                                            (i) => i.type == c.type
                                          ) > -1
                                        )
                                      }),
                                      function (c, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: { display: "flex" },
                                          },
                                          [
                                            _c("a-input-number", {
                                              attrs: { min: 0 },
                                              model: {
                                                value: c.value,
                                                callback: function ($$v) {
                                                  _vm.$set(c, "value", $$v)
                                                },
                                                expression: "c.value",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "padding-left": "5px",
                                                  "padding-right": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.getUnit(c.type))
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._l(
                                      _vm.calcUnits.filter((c) => {
                                        return (
                                          _vm.formData.calcUnits.findIndex(
                                            (i) => i.type == c.type
                                          ) == -1
                                        )
                                      }),
                                      function (c, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: { display: "flex" },
                                          },
                                          [
                                            _c("a-input-number", {
                                              attrs: { min: 0 },
                                              model: {
                                                value: c.value,
                                                callback: function ($$v) {
                                                  _vm.$set(c, "value", $$v)
                                                },
                                                expression: "c.value",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "padding-left": "5px",
                                                  "padding-right": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.getUnit(c.type))
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ])
        : _c(
            "div",
            { attrs: { slot: "form" }, slot: "form" },
            [
              _c("chapter-calendar", {
                attrs: {
                  tasks: _vm.tasks,
                  autoCalculate: _vm.autoCalculate,
                  dateType: _vm.dateType,
                  chapters: _vm.formData.chapters,
                  checkedList: _vm.checkedList,
                },
                on: { closeCalendar: _vm.closeCalendar },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }