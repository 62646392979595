<template>
  <drawer-layout
    :title="setTitle"
    ref="layout"
    :show="open"
    :isForm="isForm"
    :drawerWidth="800"
    :permission="isTongChou ? 'production:chapter:button:edit' : 'production:chapter:button:edit:isTongchou'"
    @show="show"
    :btnLoading="btnLoading"
    :tasks="tasks"
    :isChapter="isNewForm"
    @closed="closedModel"
    @save="saveForm"
    @edit="edit"
    @showScreen="showScreen"
  >
    <div class="">
      <info-row label="标题">{{ curRow.chapterName }}</info-row>
      <info-row label="序号">{{ curRow.sequenceNo>=0 ? curRow.sequenceNo : '' }}</info-row>
      <info-row label="结算数量">{{
        getCalcUnits(
          curRow.calcUnits
            ? curRow.calcUnits.filter(c => {
                return calcUnits.findIndex(i => i.type == c.type) > -1&&(c.value&&c.value>0)
              })
            : []
        )
      }}</info-row>
      <info-row label="章节状态">{{ curRow.chapterState }}</info-row>
      <info-row label="当前阶段">{{ curRow.stageName }}</info-row>
      <info-row label="当前人员"
        ><img
          style="width:30px;height:30px;border-radius:50%;margin-right:10px;"
          :src="curRow.user ? curRow.user.avatar : ''"
          alt=""
        />{{ curRow.user ? curRow.user.userName : '' }}</info-row
      >
      <info-row label="当前截止日期">{{ curRow.currDeadline }}</info-row>
      <info-row label="交稿日期">{{ curRow.deliveryDate }}</info-row>
    </div>
    <div v-if="$refs.layout && !$refs.layout.isFullScreen" slot="form">
      <div v-if="isNewForm">
        <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
          <a-form-model-item label="章节数量" v-if="curStep == 0">
            <a-input-number
              placeholder="请输入1-20的整数数字"
              :min="1"
              @change="nextStep"
              v-model="chatperCount"
              class="w-40"
            ></a-input-number>
            <span style="margin-left:5px;font-size:12px;color:#bfbfbf">请输入1-20的整数数字</span>
          </a-form-model-item>
          <a-form-model-item label="自动排期" v-if="curStep == 0">
            <div style="display:flex;height: 40px;align-items: center;justify-content: space-between;">
              <a-radio-group @change="radioAutoCalculateChange" v-model="autoCalculate">
                <a-radio value="1">是</a-radio>
                <a-radio value="0">否</a-radio>
              </a-radio-group>
              <div v-if="autoCalculate=='1'" style="display:flex;flex: 1;justify-content: space-around;">
                <a-tooltip v-if="workMsgs.length" overlayClassName="user-msg-tooltip" color="white" placement="bottom">
                  <template #title>
                    <div>
                      <p style="padding-left:15px;font-weight:700">未填写人员</p>
                      <div style="height:300px;overflow-y:auto ;">
                        <p v-for="item in workMsgs" :key="item.userId">
                          <img
                            style="width:35px;height:35px;border-radius: 50%;margin-left:15px"
                            :key="item.userId"
                            :src="item.avatar"
                          />
                          <span style="margin-left:15px">{{ item.userName }}</span>
                        </p>
                      </div>
                    </div>
                  </template>
                  <a-checkbox
                    @mouseenter="handleMouseEnter('可工作日')"
                    :checked="checkedList.some(item => item == '可工作日')"
                    @change="e => onCheckChange(e, '可工作日')"
                  >
                    可工作日
                  </a-checkbox>
                </a-tooltip>
                <a-checkbox
                  v-else
                  @mouseenter="handleMouseEnter('可工作日')"
                  :checked="checkedList.some(item => item == '可工作日')"
                  @change="e => onCheckChange(e, '可工作日')"
                >
                  可工作日
                </a-checkbox>
                <a-tooltip v-if="stageMsgs.length" overlayClassName="user-msg-tooltip" color="white" placement="bottom">
                  <template #title>
                    <div>
                      <p style="padding-left:15px;font-weight:700">未填写人员</p>
                      <div style="height:300px;overflow-y:auto ;">
                        <p v-for="item in stageMsgs" :key="item.userId">
                          <img
                            style="width:35px;height:35px;border-radius: 50%;margin-left:15px"
                            :key="item.userId"
                            :src="item.avatar"
                          />
                          <span style="margin-left:15px">{{ item.userName }}</span>
                        </p>
                      </div>
                    </div>
                  </template>
                  <a-checkbox
                    @mouseenter="handleMouseEnter('阶段耗时')"
                    :checked="checkedList.some(item => item == '阶段耗时')"
                    @change="e => onCheckChange(e, '阶段耗时')"
                  >
                    阶段耗时
                  </a-checkbox>
                </a-tooltip>
                <a-checkbox
                  v-else
                  @mouseenter="handleMouseEnter('阶段耗时')"
                  :checked="checkedList.some(item => item == '阶段耗时')"
                  @change="e => onCheckChange(e, '阶段耗时')"
                >
                  阶段耗时
                </a-checkbox>
                <a-tooltip v-if="numMsgs.length" overlayClassName="user-msg-tooltip" color="white" placement="bottom">
                  <template #title>
                    <div>
                      <p style="padding-left:15px;font-weight:700">未填写人员</p>
                      <div style="height:300px;overflow-y:auto ;">
                        <p v-for="item in numMsgs" :key="item.userId">
                          <img
                            style="width:35px;height:35px;border-radius: 50%;margin-left:15px"
                            :key="item.userId"
                            :src="item.avatar"
                          />
                          <span style="margin-left:15px">{{ item.userName }}</span>
                        </p>
                      </div>
                    </div>
                  </template>
                  <a-checkbox
                    @mouseenter="handleMouseEnter('可安排数量')"
                    :checked="checkedList.some(item => item == '可安排数量')"
                    @change="e => onCheckChange(e, '可安排数量')"
                  >
                    可安排数量
                  </a-checkbox>
                </a-tooltip>
                <a-checkbox
                  v-else
                  @mouseenter="handleMouseEnter('可安排数量')"
                  :checked="checkedList.some(item => item == '可安排数量')"
                  @change="e => onCheckChange(e, '可安排数量')"
                >
                  可安排数量
                </a-checkbox>
              </div>

              <!--               <a-checkbox-group
                v-if="autoCalculate == '1'"
                style="display:flex;flex: 1;justify-content: space-around;"
                v-model="checkedList"
                :options="plainOptions"
              /> -->
            </div>
          </a-form-model-item>
          <div class="list-form">
            <a-row :gutter="16">
              <a-col :span="3"> <div class="py-3 ">章节序号</div> </a-col>
              <a-col :span="3">
                <div class="py-3 ">
                  标题<span style="margin-left:5px;color: #bfbfbf;font-size:12px;">非必填</span>
                </div></a-col
              >
              <a-col :span="5"> <div class="py-3 ">结算数量</div> </a-col>
              <a-col :span="7">
                <div class="py-3 ">
                  <a-radio-group @change="radioChange" v-model="dateType">
                    <a-radio value="1">给稿日期</a-radio>
                    <a-radio value="2">交稿日期</a-radio>
                  </a-radio-group>
                </div>
              </a-col>
              <a-col :span="4">
                <div class="py-3 ">{{ dateType == '1' ? '预计交稿日期' : '预计给稿日期' }}</div>
              </a-col>
              <!-- <a-col :span="5"> <div class="py-3 tc-light">给稿日期</div> </a-col>
              <a-col :span="5"> <div class="py-3 tc-light">交稿日期</div> </a-col> -->
            </a-row>
            <a-row :gutter="16" v-for="(item, index) in formData.chapters" :key="index" class="my-4" :style="index==formData.chapters.length-1?'padding-bottom:36px;':''">
              <a-col :span="3">
                <a-input-number
                  v-model="item.sequenceNo"
                  @change="
                    e => {
                      sequenceNoChange(e, index, item)
                    }
                  "
                ></a-input-number>
              </a-col>
              <a-col :span="3">
                <a-input v-model="item.chapterName"></a-input>
              </a-col>
              <a-col
                class="pageCount-input"
                :span="5"
                style="display:flex;justify-content: space-between;align-items: center;"
              >
                <span
                  style="display:flex;justify-content: space-between;align-items: center;"
                  v-for="(i, idx) in item.calcUnits"
                  :key="idx"
                >
                  <a-input-number :min="0" v-model="i.value"></a-input-number
                  ><span style="padding-left:5px;padding-right:5px">{{ getUnit(i.type) }}</span>
                </span>
              </a-col>
              <a-col :span="7">
                <a-date-picker
                  v-if="dateType == '1'"
                  format="YYYY-MM-DD HH:mm"
                  valueFormat="YYYY-MM-DD HH:mm"
                  :show-time="{ format: 'HH:mm', defaultValue: moment('23:59', 'HH:mm') }"
                  placeholder="请选择具体日期"
                  @change="timeChange('draftTime', index, item)"
                  v-model="item.draftTime"
                />
                <a-date-picker
                  v-else
                  valueFormat="YYYY-MM-DD HH:mm"
                  :show-time="{ format: 'HH:mm', defaultValue: moment('23:59', 'HH:mm') }"
                  format="YYYY-MM-DD HH:mm"
                  placeholder="请选择具体日期"
                  @change="timeChange('deliveryTime', index, item)"
                  v-model="item.deliveryTime"
                />
              </a-col>
              <a-col :span="4">
                <a-spin :spinning="item.loading">
                  <span style="line-height:32px;" v-if="dateType == '1'">
                    {{ item.deliveryTime ? item.deliveryTime : '—' }}
                  </span>
                  <span style="line-height:32px;" v-else>
                    {{ item.draftTime ? item.draftTime : '—' }}
                  </span>
                </a-spin>
              </a-col>
              <!-- <a-col :span="5">
                <j-date :trigger-change="true" placeholder="交稿日期" v-model="item.predefinedEndDate"></j-date>
              </a-col> -->
            </a-row>
          </div>
        </a-form-model>
        <!--         <div class="py-4">
          <a-button type="primary" @click="nextStep" v-if="curStep == 0">下一步</a-button>
        </div> -->
      </div>
      <div v-else>
        <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
          <a-form-model-item label="章节序号">
            <a-input-number v-model="formData.sequenceNo" placeholder="请输入序号" />
          </a-form-model-item>
          <a-form-model-item label="标题" prop="chapterName">
            <a-input v-model="formData.chapterName"></a-input>
          </a-form-model-item>
          <a-form-model-item label="结算数量" prop="calcUnits">
            <div v-if="formData.calcUnits" style="display:flex;justify-content: space-between;align-items: center;">
              <div
                style="display:flex;"
                v-for="(c, index) in formData.calcUnits.filter(c => {
                  return calcUnits.findIndex(i => i.type == c.type) > -1
                })"
                :key="index"
              >
                <a-input-number :min="0" v-model="c.value"></a-input-number
                ><span style="padding-left:5px;padding-right:5px">{{ getUnit(c.type) }}</span>
              </div>
              <div
                style="display:flex;"
                v-for="(c, index) in calcUnits.filter(c => {
                  return formData.calcUnits.findIndex(i => i.type == c.type) == -1
                })"
                :key="index"
              >
                <a-input-number :min="0" v-model="c.value"></a-input-number
                ><span style="padding-left:5px;padding-right:5px">{{ getUnit(c.type) }}</span>
              </div>
            </div>
          </a-form-model-item>
          <!--             <a-form-model-item label="日期自动推算">
              <a-switch checked-children="是" un-checked-children="否" v-model="formData.autoCalculate"></a-switch>
            </a-form-model-item>
            <a-form-model-item label="给稿日期">
              <j-date :trigger-change="true" placeholder="给稿日期" v-model="formData.predefinedStartDate"></j-date>
            </a-form-model-item>
            <a-form-model-item label="交稿日期">
              <j-date :trigger-change="true" placeholder="交稿日期" v-model="formData.predefinedEndDate"></j-date>
            </a-form-model-item> -->
        </a-form-model>
      </div>
    </div>
    <div v-else slot="form">
      <chapter-calendar
        :tasks="tasks"
        :autoCalculate="autoCalculate"
        :dateType="dateType"
        :chapters="formData.chapters"
        :checkedList="checkedList"
        @closeCalendar="closeCalendar"
      />
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { putAction, postAction } from '@/api/manage'
import chapterCalendar from './chapterCalendar.vue'
import moment from 'moment'
import { simpleDebounce } from '@/utils/util'
import { getAction } from '../../../api/manage'
export default {
  components: {
    chapterCalendar
  },
  mixins: [DrawerMixins],
  props: {
    calcUnits: {
      type: Array,
      default: () => {
        ;[]
      }
    },
    isTongChou: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layout: {
        labelCol: {
          span: 5
        },
        wrapperCol: {
          span: 19
        }
      },
      isNewForm: true,
      defaultUser: [],
      defaultAssUser: [],
      chatperCount: 1,
      plainOptions: ['可工作日', '阶段耗时', '可安排数量'],
      checkedList: ['可工作日', '阶段耗时', '可安排数量'],
      curStep: 0,
      defaultFields: {
        autoCalculate: true,
        chapterId: '',
        chapterName: '',
        predefinedEndDate: '',
        predefinedStartDate: '',
        sequenceNo: ''
      },
      formData: {
        sequenceNo: '',
        chapterName: '',
        predefinedStartDate: '',
        predefinedEndDate: '',
        autoCalculate: true,
        pageCount: 0,
        pieceCount: 0,
        chapters: []
      },
      dateType: '1',
      autoTypes: [
        { value: true, text: '自动' },
        { value: false, text: '否' }
      ],
      rules: {
        platformName: [{ required: true, message: '请输入平台名称', trigger: 'blur' }]
      },
      url: {
        edit: '/production/edit_chapter',
        add: '/production/create_chapter'
      },
      autoCalculate: '1',
      tasks: [],
      numMsgs: [],
      workMsgs: [],
      stageMsgs: []
    }
  },
  watch: {
    show(n) {},
    /*     formData: {
      deep: true,
      handler(n,o) {
        console.log('新',n)
        console.log('旧',o)
        if (this.formData.chapters.length && this.formData.chapters.every(item => item.sequenceNo === '')) {
          return
        }
        if (this.setTitle == '新建') this.getCalcDate()
      }
    } */
    checkedList() {
      this.formData.chapters.forEach((item, index) => {
        if (item.sequenceNo !== '' && (item.draftTime || item.deliveryTime)) {
          this.getCalcDate(item, index)
        }
      })
    },
    autoCalculate() {
      this.formData.chapters.forEach((item, index) => {
        if (item.sequenceNo !== '' && (item.draftTime || item.deliveryTime)) {
          this.getCalcDate(item, index)
        }
      })
    }
  },
  methods: {
    moment,
    handleMouseEnter: simpleDebounce(async function(type) {
      this.handleMouseEnterSim(type)
    }, 500),
    async handleMouseEnterSim(type) {
      let res = {}
      if (type == '可工作日') {
        res = await getAction('/taskSchedule/createChapter/noneWorkDayUsers?productId=' + this.$route.params.id)
        if (res.code == 200) {
          this.workMsgs = res.data
        } else {
          this.$message.error(res.msg)
        }
      } else if (type == '阶段耗时') {
        res = await getAction('/taskSchedule/createChapter/noneIntervalStage?productId=' + this.$route.params.id)
        if (res.code == 200) {
          this.stageMsgs = res.data
        } else {
          this.$message.error(res.msg)
        }
      } else if (type == '可安排数量') {
        res = await getAction('/taskSchedule/createChapter/noneInputScheduleUsers?productId=' + this.$route.params.id)
        if (res.code == 200) {
          this.numMsgs = res.data
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    onCheckChange(e, type) {
      if (e.target.checked) {
        this.checkedList.push(type)
      } else {
        this.checkedList.splice(this.checkedList.indexOf(type), 1)
      }
    },
    async showScreen() {
      let valid = true
      this.formData.chapters?.forEach(item => {
        if (this.dateType == '1' && !item.deliveryTime) {
          valid = false
        }
        if (this.dateType == '2' && !item.draftTime) {
          valid = false
        }
      })
      if (!valid) {
        this.$message.error('未获取排期，无法预览')
        return
      }
      if (this.$refs.layout) {
        let c = JSON.parse(JSON.stringify(this.formData.chapters))
        c.forEach(i => {
          i.tasks?.forEach(item => {
            if (item.taskStatus == '驳回') {
              item.taskStatus = 'REJECTING'
            } else if (item.taskStatus == '可开始') {
              item.taskStatus = 'STARTED'
            } else if (item.taskStatus == '等待') {
              item.taskStatus = 'WAITING'
            } else if (item.taskStatus == '已完成') {
              item.taskStatus = 'FINISHED'
            }
          })
        })
        delete c.taskStatus
        const data = {
          productId: this.$route.params.id,
          isAuto: this.autoCalculate == '1', //是否自动排期
          isWorkDay: this.checkedList.some(item => item == '可工作日'), // 是否考虑可工作日
          isInterval: this.checkedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
          isScheduleCount: this.checkedList.some(item => item == '可安排数量'), // 是否考虑任务排满
          isDraftBegin: this.dateType == '1', // 是否从给稿开始排
          chapters: c,
          allChapters: this.formData.chapters
        }
        const res = await postAction('/taskSchedule/createChapter/checkExists', data)
        if (res.code == 200) {
          this.$refs.layout.isFullScreen = !this.$refs.layout.isFullScreen
        } else {
          this.$message.error(res.msg || res.message)
        }
      }
    },
    timeChange(type, index, item) {
      if (!index) {
        if (type == 'draftTime') {
          this.formData.chapters.forEach((item, index) => {
            item.draftTime = this.formData.chapters[0].draftTime
            if (item.sequenceNo !== '') {
              this.getCalcDate(item, index)
            }
          })
        } else {
          this.formData.chapters.forEach((item, index) => {
            item.deliveryTime = this.formData.chapters[0].deliveryTime
            if (item.sequenceNo !== '') {
              this.getCalcDate(item, index)
            }
          })
        }
        return
      }
      if (item.sequenceNo !== '') {
        this.getCalcDate(item, index)
      }
    },
    getCalcDate: simpleDebounce(async function(chapter, index) {
      this.getCalcDateSim(chapter, index)
    }, 500),
    async getCalcDateSim(chapter, index) {
      let valid = true
      this.formData.chapters?.forEach((item, idx) => {
        item.calcUnits?.forEach(i => {
          delete i.id
          delete i.gmtCreate
        })
        if (item.sequenceNo == chapter.sequenceNo && index != idx) {
          valid = false
        }
      })
      if (!valid) {
        this.$message.error('存在相同章节序号，无法自动排期')
        return
      }
      /* let c = JSON.parse(JSON.stringify(chapter)) */
      this.formData.chapters.forEach(c => {
        if (c.sequenceNo == chapter.sequenceNo) {
          c = chapter
        }
        c.tasks?.forEach(item => {
          if (item.taskStatus == '驳回') {
            item.taskStatus = 'REJECTING'
          } else if (item.taskStatus == '可开始') {
            item.taskStatus = 'STARTED'
          } else if (item.taskStatus == '等待') {
            item.taskStatus = 'WAITING'
          } else if (item.taskStatus == '已完成') {
            item.taskStatus = 'FINISHED'
          }
        })
        delete c.taskStatus
      })

      const data = {
        productId: this.$route.params.id,
        isAuto: this.autoCalculate == '1', //是否自动排期
        isWorkDay: this.checkedList.some(item => item == '可工作日'), // 是否考虑可工作日
        isInterval: this.checkedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
        isScheduleCount: this.checkedList.some(item => item == '可安排数量'), // 是否考虑任务排满
        isDraftBegin: this.dateType == '1', // 是否从给稿开始排
        chapters: this.formData.chapters,
        allChapters: this.formData.chapters
      }
      this.formData.chapters?.forEach(item => {
        item.loading = true
      })

      const res = await postAction('/taskSchedule/createChapter/calcDate', data)
      this.formData.chapters?.forEach(item => {
        item.loading = false
      })
      if (res.code == 200) {
        if (res.data && res.data.chapters && res.data.chapters.length) {
          /*           if (res.data.chapters[0].deliveryTime) chapter.deliveryTime = res.data.chapters[0].deliveryTime
          if (res.data.chapters[0].draftTime) chapter.draftTime = res.data.chapters[0].draftTime */
          this.formData.chapters?.forEach(c => {
            res.data.chapters?.forEach(item => {
              if (item.sequenceNo == c.sequenceNo) {
                if (item.deliveryTime) {
                  c.deliveryTime = item.deliveryTime
                }
                if (item.draftTime) {
                  c.draftTime = item.draftTime
                }
                c.tasks = item.tasks
              }
            })
          })
          /* chapter.tasks = res.data.chapters[0].tasks */
          this.formData.chapters[index] = chapter
          this.tasks = []
          this.formData.chapters.forEach(item => {
            if (item.tasks) {
              this.tasks = [...this.tasks, ...item.tasks]
            }
          })
          this.tasks.forEach(item => {
            item.taskStatus = '等待'
            item.id = item.chapterOrder + '000' + item.stageId
          })
          this.$forceUpdate()
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    radioAutoCalculateChange(e) {
      if (e.target.value) {
        this.formData.autoCalculate = true
      } else {
        this.formData.autoCalculate = false
      }
    },
    getUnit(type) {
      let unit = ''
      switch (type) {
        case 'EACH':
          unit = '个'
          break
        case 'CHAPTER':
          unit = '话'
          break
        case 'VOLUME':
          unit = '部'
          break
        case 'PAGE':
          unit = '页'
          break
        case 'WORD':
          unit = '字'
          break
        case 'THOUSAND':
          unit = '千字'
          break
        case 'GRID':
          unit = '格'
          break
        case 'PERCENT':
          unit = '%'
          break
      }
      return unit
    },
    getCalcUnits(calcUnits) {
      let text = []
      calcUnits?.forEach(item => {
        if (item.value) {
          let unit = ''
          switch (item.type) {
            case 'EACH':
              unit = item.value + '个'
              break
            case 'CHAPTER':
              unit = item.value + '话'
              break
            case 'VOLUME':
              unit = item.value + '部'
              break
            case 'PAGE':
              unit = item.value + '页'
              break
            case 'WORD':
              unit = item.value + '字'
              break
            case 'THOUSAND':
              unit = item.value + '千字'
              break
            case 'GRID':
              unit = item.value + '格'
              break
            case 'PERCENT':
              unit = item.value + '%'
              break
          }
          text.push(unit)
        }
      })
      text = text.join('')
      return text
    },
    save() {
      const self = this
      this.setFormData()
      this.formData.chapters?.forEach(item => {
        item.calcUnits?.forEach(i => {
          delete i.id
          delete i.gmtCreate
        })
      })
      this.formData.productId = this.$route.params.id
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (self.isEdit || self.isEditForm) {
            self.formData.platformId = self.curRow.platformId
            putAction(self.url.edit, self.formData).then(async res => {
              self.$refs.layout.loading = false
              if (res.success) {
                if (self.isBlack) {
                  const response = await postAction('/qualityInspection/blackWhiteSync')
                  if (response.data.code != 0) {
                    self.$message.error(response.data)
                  }
                }
                if (
                  res.data.some(item => {
                    return item.overSchedule
                  })
                ) {
                  self.$message.warning('有截止日早于当天的任务，请手动设定截止日')
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.btnLoading = false
                self.closed()
              } else {
                self.btnLoading = false
                self.$message.error(res.message || res.msg)
              }
            })
          } else {
            console.log('新建URL', self.url.add)
            console.log('新建参数', self.formData)
            postAction(self.url.add, self.formData).then(async res => {
              self.$refs.layout.loading = false
              if (res.success) {
                if (self.isBlack) {
                  const response = await postAction('/qualityInspection/blackWhiteSync')
                  if (response.data.code != 0) {
                    self.$message.error(response.data)
                  }
                }
                if (
                  res.data.some(item => {
                    return item.overSchedule
                  })
                ) {
                  self.$message.warning('有截止日早于当天的任务，请手动设定截止日')
                } else {
                  self.$message.success(res.message || res.msg ? '保存成功' : '')
                }
                self.isSaved = true
                self.closed()
                self.closedModel()
                self.btnLoading = false
              } else {
                self.btnLoading = false
                self.$message.error(res.message || res.msg)
              }
            })
          }
        }
      })
    },
    radioChange() {
      this.formData.chapters.forEach(item => {
        item.predefinedStartDate = null
        item.predefinedEndDate = null
      })
    },
    autoCalculateChange(autoCalculate, chapters) {
      for (let item of chapters) {
        item.autoCalculate = autoCalculate
      }
      this.formData.chapters.forEach(item => {
        item.predefinedStartDate = null
        item.predefinedEndDate = null
      })
    },
    initForm() {
      this.formData = {
        sequenceNo: '',
        chapterName: '',
        predefinedStartDate: '',
        predefinedEndDate: '',
        autoCalculate: true,
        pageCount: 0,
        chapters: []
      }
      this.chatperCount=1 
      this.$refs.layout.isFullScreen = false
      this.isNewForm = true
    },

    show() {
      console.log('显示...')
      this.title = this.curRow.chapterName
      this.curRow.sequenceNo = parseFloat((this.curRow.sequenceNo - 0).toFixed(1))
      if (!this.formData.chapters.length) {
        let calcUnits = this.calcUnits.map(item => {
          return { value: item.type == 'CHAPTER' ? 1 : item.value, type: item.type }
        })
        this.formData.chapters.push(
          Object.assign({ productionId: this.$route.params.id }, { loading: false }, this.defaultFields, { calcUnits })
        )
      }
      // this.getPosition()
      // this.getStageList()
    },
    closedModel() {
      this.curStep = 0
      this.chatperCount = 1
      if (!this.isNewForm) {
        const self = this
        let newData = JSON.stringify(this.formData)
        let oldData = JSON.stringify(this.oldFormData)
        if (newData != oldData) {
          this.$confirm({
            title: '提示',
            content: '您尚未保存数据，确定要退出吗？',
            onOk() {
              self.initForm()
              self.exit()
            }
          })
        } else {
          this.exit()
        }
      } else {
        this.initForm()
        this.exit()
      }
    },
    closeCalendar() {
      this.initForm()
      this.exit()
    },
    edit() {
      this.formData.chapterId = this.curRow.chapterId
      this.formData.sequenceNo = this.curRow.sequenceNo || ''
      this.formData.chapterName = this.curRow.chapterName
      this.formData.calcUnits = this.curRow.calcUnits
      this.formData.predefinedStartDate = this.curRow.predefinedStartDate
      this.formData.predefinedEndDate = this.curRow.predefinedEndDate
      this.formData.autoCalculate = true
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      this.isNewForm = false
    },
    saveForm: simpleDebounce(async function() {
      this.saveFormSim()
    }, 500),
    saveFormSim() {
      let valid = true
      this.formData.chapters?.forEach(item => {
        if (this.dateType == '1' && !item.deliveryTime) {
          valid = false
        }
        if (this.dateType == '2' && !item.draftTime) {
          valid = false
        }
      })
      if (!valid&&this.isNewForm) {
        this.$message.error('未获取排期，无法保存')
        return
      }
      this.$refs.layout.loading = true
      this.btnLoading = true
      console.log('chapter-modal saveForm')
      if (this.isNewForm) {
        this.save()
      } else {
        //编辑章节的
        let self = this
        if (this.formData.calcUnits.length) {
          /*           this.formData.calcUnits.forEach(item => {
            this.calcUnits.forEach(i => {
              if (i.type != item.type) {
                this.formData.calcUnits.push(i)
              }
            })
          }) */
          this.calcUnits.forEach(i => {
            if (this.formData.calcUnits.every(item => i.type != item.type)) {
              this.formData.calcUnits.push(i)
            }
          })
        } else {
          this.formData.calcUnits = this.calcUnits
        }
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            delete self.formData.autoCalculate
            delete self.formData.chapters
            if (self.curRow.pagePattern == 'PIECE_PAGE') {
              self.formData.pageCount = self.formData.pageCount
              self.formData.pieceCount = self.formData.pieceCount + ''
            } else if (self.curRow.pagePattern == 'PAGE') {
              self.formData.pageCount = self.formData.pageCount + ''
            }
            putAction('/production/edit_chapter', self.formData).then(res => {
              self.$refs.layout.loading = false
              self.btnLoading = false
              if (res.success) {
                self.$message.success(res.message)
                self.isSaved = true
                self.closed()
              } else {
                self.$message.error(res.message)
              }
            })
          } else {
            self.btnLoading = false
          }
        })
      }
      this.$emit('afterSave')
    },

    setFormData() {},
    nextStep() {
      if (this.curStep == 0 && this.chatperCount > 20) {
        this.$message.error('请输入1-20的整数数字')
        return
      }
      if (this.formData.chapters.length >= this.chatperCount) {
        let num = this.formData.chapters.length - this.chatperCount
        for (let index = 0; index < num; index++) {
          this.formData.chapters.pop()
        }
        return
      }
      for (let index = 0; index < this.chatperCount; index++) {
        const element = this.chatperCount[index]
        let calcUnits = this.calcUnits.map(item => {
          return { value: item.type == 'CHAPTER' ? 1 : item.value, type: item.type }
        })
        if (this.formData.chapters.length < this.chatperCount) {
          this.formData.chapters.push(
            Object.assign({ productionId: this.$route.params.id }, { loading: false }, this.defaultFields, {
              calcUnits
            })
          )
        }
      }
    },
    onStartChange(val) {
      console.log(val)
    },
    onEndChange(val) {
      console.log(val)
    },
    autoCalcCheckBoxChanged(e) {
      if (e.target.checked) {
        this.formData.chapters.forEach(item => {
          item.autoCalculate = true
        })
      } else {
        this.formData.chapters.forEach(item => {
          item.autoCalculate = false
        })
      }
    },
    sequenceNoChange(e, index, item) {
      if (index == 0) {
        let val = e
        this.formData.chapters.forEach(item => {
          item.sequenceNo = val++
        })
        this.formData.chapters.forEach((item, index) => {
          if (item.draftTime || item.deliveryTime) {
            this.getCalcDate(item, index)
          }
        })
        return
      }
      if (item.draftTime || item.deliveryTime) {
        this.getCalcDate(item, index)
      }
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
/deep/ .ant-input-number-handler-wrap {
  display: none !important;
}
/deep/ .ant-col-3 {
  width: 15.5% !important;
}
/deep/ .ant-form-item-label label {
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-form-item {
  max-width: 676px;
}
/deep/ .ant-drawer-body {
  padding-right: 5px !important;
}
.list-form{
  height:700px;
  overflow-y: auto;
}
</style>
